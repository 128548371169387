<template>
  <v-footer app id="footer" :elevation="18">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" sm="7" md="6" lg="4" class="px-0">
          <v-btn
            v-if="!isWebOrdersModeEnabled"
            @click="goToRoute"
            color="info"
            class="px-5"
            block
            depressed
            x-large
            rounded
          >
            {{ successPayment ? "Finalizar" : "Reintentar" }}
          </v-btn>
          <v-btn
            v-else
            @click="goToRoute"
            color="info"
            class="px-5"
            block
            depressed
            x-large
            rounded
          >
            {{ successPayment ? "Volver al inicio" : "Ver mi pedido" }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import shopUtils from "@/mixins/shopUtils.js";

export default {
  mixins: [shopUtils],
  provide: {
    heading: { align: "center" },
  },

  methods: {
    goToRoute() {
      const { commerceId, table } = this.$route.query;
      if (this.successPayment) {
        this.$router.push({
          name: this.isDelivery ? "MenuWeborders" : "MenuOrder",
          query: this.isDelivery ? { commerceId } : { commerceId, table },
        });
      } else {
        this.$router.push({
          name: this.isDelivery
            ? "MenuWebordersPayment"
            : "MenuOrderDetailsPayment",
          query: this.isDelivery ? { commerceId } : { commerceId, table },
        });
      }
    },
  },

  computed: {
    successPayment() {
      return this.$route.meta.successPayment;
    },
    isDelivery() {
      return this.$route.meta.isDelivery;
    },
  },
};
</script>
<style lang="sass" scoped>
#footer
  background-position: center bottom
  a
    text-decoration: none

#cart-button-text
  letter-spacing: 0.06rem !important
</style>
