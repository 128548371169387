import { render, staticRenderFns } from "./FooterMenuOrderDetailsPaymentThankYou.vue?vue&type=template&id=643dfffc&scoped=true"
import script from "./FooterMenuOrderDetailsPaymentThankYou.vue?vue&type=script&lang=js"
export * from "./FooterMenuOrderDetailsPaymentThankYou.vue?vue&type=script&lang=js"
import style0 from "./FooterMenuOrderDetailsPaymentThankYou.vue?vue&type=style&index=0&id=643dfffc&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "643dfffc",
  null
  
)

export default component.exports