export default {
  methods: {
    validatePickupPointSelection() {
      if (this.$store.state.menu.profile.pickUpPoints?.length > 0) {
        const result = this.pickUpPoint != null;
        if (!result) {
          this.$store.commit("snackbar/OPEN_SNACKBAR", {
            msg: "Debe elegir un punto de retiro antes de agregar o modificar productos",
            success: false,
          });
        }

        return result;
      } else return true;
    },
  },
  computed: {
    isKioskModeEnabled() {
      return this.$store.state.menu.profile.isKioskModeEnabled;
    },
    isWebOrdersModeEnabled() {
      return (
        this.$store.state.menu.profile.operationMode == 1 &&
        !this.isKioskModeEnabled
      );
    },
    isValidatorModeEnabled() {
      return (
        this.$store.state.menu.profile.operationMode == 2 &&
        !this.isKioskModeEnabled
      );
    },
    hasChildShops() {
      return this.$store.state.menu.profile.childShops?.length > 0;
    },
    isValidAmount() {
      if (
        this.$store.state.menu.profile.isMinimumAmountEnabled &&
        this.getDeliveryMethod == "delivery"
      )
        return this.$store.state.deliveryCart.minimumAmountValid;
      else return true;
    },
    isThereAtLeastOneChildShopOpen() {
      return this.$store.state.menu.profile.childShops?.some((x) => x.isOpen);
    },
    childShops() {
      return this.$store.state.menu.profile.childShops;
    },
    isShopOpen() {
      if (this.$store.state.menu.profile.isOpen === undefined) {
        return true;
      } else return this.$store.state.menu.profile.isOpen;
    },
    isDeliveryOpen() {
      if (this.$store.state.menu.profile.isDeliveryOpen === undefined) {
        return true;
      } else return this.$store.state.menu.profile.isDeliveryOpen;
    },
    getDeliveryMethod() {
      return this.$store.getters["deliveryCart/getDeliveryMethod"];
    },
    pickUpPoint() {
      if (this.$store.state.deliveryCart.selectedPickUpPoint) {
        const pup = this.$store.state.menu.profile.pickUpPoints.find(
          (x) => x.id == this.$store.state.deliveryCart.selectedPickUpPoint
        );

        return pup;
      } else return null;
    },
    isPickupUpPointOpen() {
      if (this.$store.state.deliveryCart.selectedPickUpPoint) {
        const pup = this.$store.state.menu.profile.pickUpPoints.find(
          (x) => x.id == this.$store.state.deliveryCart.selectedPickUpPoint
        );

        return pup.isOpen;
      } else return true;
    },
  },
};
